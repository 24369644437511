<template>
  <BCard class="border" bg-variant="dark">
    <AccountHeader title="Welcome back" />
    <Alert />
    <BForm v-on:submit.prevent="onSubmit" novalidate>
      <BFormGroup id="emailInputGroup" label-sr-only labelFor="emailInput" label="Email">
        <BFormInput
          aria-describedby="emailInputLiveFeedback"
          id="emailInput"
          placeholder="Email"
          type="email"
          v-bind:state="$v.email.$dirty ? !$v.email.$error : null"
          v-model="email"
        >
        </BFormInput>
        <BFormInvalidFeedback id="emailInputLiveFeedback">
          Email is required
        </BFormInvalidFeedback>
      </BFormGroup>
      <BFormGroup id="passwordInputGroup" label="Password" labelFor="passwordInput" label-sr-only>
        <BFormInput
          aria-describedby="passwordResetHelpBlock passwordInputLiveFeedback"
          id="passwordInput"
          placeholder="Password"
          type="password"
          v-bind:state="$v.password.$dirty ? !$v.password.$error : null"
          v-model="password"
        >
        </BFormInput>
        <BFormText id="passwordResetHelpBlock" class="text-right">
          <BLink v-bind:to="{ name: 'account-user-reset-password' }">Forgot your password?</BLink>
        </BFormText>
        <BFormInvalidFeedback id="passwordInputLiveFeedback">
          Password must be at least 8 characters
        </BFormInvalidFeedback>
      </BFormGroup>
      <BBtn type="submit" variant="primary" block v-bind:disabled="loading" class="mb-3">
        <BSpinner label="loading..." v-if="loading" small />
        <span v-else>Sign In</span>
      </BBtn>
      <div class="text-center">
        <div class="small text-muted my-2">Don't have an account?</div>
        <BLink v-bind:to="{ name: 'account-user-create' }">Create your {{ brand }} account</BLink>
      </div>
    </BForm>
  </BCard>
</template>

<script>
import { email, minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import AccountHeader from "./AccountHeader.vue";
import Alert from "./Alert.vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Access the tools you need to improve your review site ratings. Sign in to your account with your email address and password."
      },
      {
        property: "og:title",
        content: "Sign In"
      },
      {
        property: "og:description",
        content:
          "Access the tools you need to improve your review site ratings. Sign in to your account with your email address and password."
      }
    ],
    title: "Sign In"
  },
  components: {
    AccountHeader,
    Alert
  },
  props: {
    reference: {
      type: String,
      required: false
    },
    verified: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      brand: process.env.VUE_APP_CLIENT_NAME,
      email: null,
      password: null
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      session: state => state.auth.session
    })
  },
  created() {
    if (this.verified === null) {
      this.$store.dispatch("alert/createAlert", "Account activated.");
    }
  },
  watch: {
    session: {
      handler(value) {
        if (value !== null) {
          this.reference != null ? this.$router.push({ name: this.reference }) : this.$router.push("/");
        }
      },
      immediate: true
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email
    },
    password: {
      minLength: minLength(8)
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("auth/signin", {
          email: this.email,
          password: this.password
        });
        this.email = this.password = null;
        this.$v.$reset();
      }
    }
  }
};
</script>
