<template>
  <div class="min-vh-100 d-flex">
    <BContainer class="align-self-center">
      <BRow class="justify-content-center my-3">
        <BCol sm="9" md="7" lg="5" xl="4">
          <div class="d-flex justify-content-center mb-4">
            <BLink v-bind:to="{ name: 'home' }" aria-label="Homepage">
              <Logo v-bind:size="50" />
            </BLink>
          </div>
          <h1 class="font-weight-light text-white text-center mb-4">
            {{ brand }}
          </h1>
          <RouterView />
        </BCol>
      </BRow>
    </BContainer>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo
  },
  data() {
    return {
      brand: process.env.VUE_APP_CLIENT_NAME
    };
  }
};
</script>
