<template>
  <BCard class="border" bg-variant="dark">
    <AccountHeader title="Create your account" />
    <AccountUserCreateForm v-bind:reference="reference" />
  </BCard>
</template>

<script>
import AccountHeader from "./AccountHeader.vue";
import AccountUserCreateForm from "./AccountUserCreateForm.vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Start improving your review scores. Create a new account with your email address and password."
      },
      {
        property: "og:title",
        content: "Register"
      },
      {
        property: "og:description",
        content: "Start improving your review scores. Create a new account with your email address and password."
      }
    ],
    title: "Register"
  },
  components: {
    AccountHeader,
    AccountUserCreateForm
  },
  props: {
    reference: {
      type: String,
      required: false
    }
  }
};
</script>
