<template>
  <BCard class="border" bg-variant="dark">
    <AccountHeader title="Reset account password" />
    <Alert />
    <BForm v-on:submit.prevent="onSubmit" novalidate>
      <BFormGroup id="emailInputGroup" label="Email" labelFor="emailInput" label-sr-only>
        <BFormInput
          id="emailInput"
          type="email"
          placeholder="Email"
          v-model="email"
          v-bind:state="$v.email.$dirty ? !$v.email.$error : null"
          aria-describedby="emailInputLiveFeedback"
        >
        </BFormInput>
        <BFormInvalidFeedback id="emailInputLiveFeedback">
          Email is required
        </BFormInvalidFeedback>
      </BFormGroup>
      <BBtn type="submit" variant="primary" block v-bind:disabled="loading" class="mb-3">
        <BSpinner label="loading..." v-if="loading" small />
        <span v-else>Continue</span>
      </BBtn>
      <div class="text-center">
        <div class="small text-muted mb-2">Don't have an account?</div>
        <BLink v-bind:to="{ name: 'account-user-create' }">Create your {{ brand }} account</BLink>
      </div>
    </BForm>
  </BCard>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import AccountHeader from "./AccountHeader.vue";
import Alert from "./Alert.vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Get back into your account. Initiate an account password reset."
      },
      {
        property: "og:title",
        content: "Reset account password"
      },
      {
        property: "og:description",
        content: "Get back into your account. Initiate an account password reset."
      }
    ],
    title: "Reset account password"
  },
  components: {
    AccountHeader,
    Alert
  },
  data() {
    return {
      brand: process.env.VUE_APP_CLIENT_NAME,
      email: null
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },
  mixins: [validationMixin],
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("auth/resetPassword", this.email);
        this.email = null;
        this.$v.$reset();
      }
    }
  }
};
</script>
