<template>
  <BCard v-if="session" class="border" bg-variant="dark">
    <AccountHeader title="Update account password" />
    <Alert />
    <BForm v-on:submit.prevent="onSubmit" novalidate>
      <BFormGroup id="passwordInputGroup" label="Password" labelFor="passwordInput" label-sr-only>
        <BFormInput
          id="passwordInput"
          type="password"
          placeholder="Password"
          v-model="password"
          v-bind:state="$v.password.$dirty ? !$v.password.$error : null"
          aria-describedby="passwordInputLiveFeedback"
        >
        </BFormInput>
        <BFormInvalidFeedback id="passwordInputLiveFeedback">
          Password is required and must be at least 8 characters
        </BFormInvalidFeedback>
      </BFormGroup>
      <BBtn type="submit" variant="primary" block v-bind:disabled="loading">
        <BSpinner label="loading..." v-if="loading" small />
        <span v-else>Save</span>
      </BBtn>
    </BForm>
  </BCard>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import AccountHeader from "./AccountHeader.vue";
import Alert from "./Alert.vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "Get back into your account. Update your password to continue."
      },
      {
        property: "og:title",
        content: "Update account password"
      },
      {
        property: "og:description",
        content: "Get back into your account. Update your password to continue."
      }
    ],
    title: "Update account password"
  },
  components: {
    AccountHeader,
    Alert
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      session: state => state.auth.session
    })
  },
  data() {
    return {
      password: null
    };
  },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8)
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store.dispatch("auth/updateUser", this.password);
        this.password = null;
        this.$v.$reset();
      }
    }
  }
};
</script>
